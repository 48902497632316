import React from "react"
import Layout from "components/layout"
import SEO from "components/seo"
import { Link } from "gatsby"

const TrainingNotes = () => {
  return (
    <Layout isVisibleBack={true}>
      <SEO title="Eğitim Notları" />
      <h1>Eğitim Notları</h1>

      <div
        style={{
          display: `flex`,
          flexDirection: `column`,
          flexWrap: `wrap`,
        }}
      >
        <Link to="/trainings/2021AguTAI/">
          Software Architecture Eğitimi (TAI-2021 Agustos)
        </Link>
        <Link to="/trainings/2021AprAselsan/">
          React Frontend Eğitimi (Aselsan-2021 Nisan)
        </Link>
        <Link to="/trainings/2021MarAselsan/">
          React Frontend Eğitimi (Aselsan-2021 Mart)
        </Link>
        <Link to="/trainings/2020DecAselsan/">
          React Frontend Eğitimi (Aselsan-2020 Aralık)
        </Link>
        <Link to="/trainings/2020DecCS/">
          React Frontend Eğitimi (Cybersoft-2020 Aralık)
        </Link>
        <Link to="/trainings/2020OctAselsan/">
          React Frontend Eğitimi (Aselsan-2020 Ekim)
        </Link>
        <Link to="/trainings/2020SepTubitakUzay/">
          Software Architecture Eğitimi (Tubitak Uzay-2020 Eylül)
        </Link>
      </div>
    </Layout>
  )
}

export default TrainingNotes
